<template>
  <b-row>
    <b-col>
      <b-row v-if="event" class="p-4 bg-mellow">
        <b-col>
          <b-row cols="12">
            <b-col>
              <h1 class="h1 mb-0">Mille Gruyère - {{ event.nwpName }}</h1>
              <h3 class="text-muted">{{ $t(event.nwpCategory) }}</h3>
            </b-col>
            <b-col sm="6" md="4" lg="3" class="text-center text-md-right" v-if="logo">
              <b-img :src="logo" fluid :alt="`Logo - ${event.nwpName}`" style="max-height:95px;"></b-img>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col sm="12" md="6" lg="6" xl="8">
              <h2>{{ $t("event_details.label_details") }}</h2>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_ort") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.venue }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_sportanlage") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.sportField }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_adresse") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.nwpAddress }}, {{ event.nwpZipCode }} {{ event.venue }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_datum") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ formatDate(event.startDate) }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_startzeit") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ formatTime(event.startTime) }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_veranstalter") }}</p>
                </b-col>
                <b-col>
                  <p v-if="event.webpage">
                    <b-link :href="event.webpage" target="_blank">{{
                      event.schoolOrOrganisation
                    }}</b-link>
                  </p>
                  <p v-else>
                    {{ event.schoolOrOrganisation }}
                  </p>
                </b-col>
              </b-row>
              <b-row v-if="event.nwpHomologation">
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_homologation") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ $t(event.nwpHomologation) }}
                  </p>
                </b-col>
              </b-row>
              <b-row v-if="event.remark">
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_spezielle_bemerkungen_zur_veranstaltung") }}</p>
                </b-col>
                <b-col>
                  <p v-html="event.remark"></p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_anmeldeschluss") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ formatDate(event.deadline) }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_nachmeldungen_auf_platz_moeglich") }}</p>
                </b-col>
                <b-col>
                  <p>
                    {{ event.registrationOnSitePossible === true ? $t("Lov.Sat.YesNo.Yes") : $t("Lov.Sat.YesNo.No") }}
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="font-weight-bold">
                  <p>{{ $t("event_details.label_startgeld") }}</p>
                </b-col>
                <b-col>
                  <p> {{ event.entryFee ? `CHF ${event.entryFee}` : '-' }}</p>
                </b-col>
              </b-row>
            </b-col>
    
            <b-col sm="12" md="6" lg="6" xl="4">
              <h2>{{ $t("event_details.label_auskunft") }}</h2>
              <b-row>
                <b-col cols="6" class="font-weight-bold">
                  <p>{{ $t("event_details.label_name") }}</p>
                </b-col>
                <b-col cols="6">
                  <p>{{ event.contactFirstName }} {{ event.contactName }}</p>
                </b-col>
              </b-row>
              <b-row v-if="event.publishMobile && event.contactMobile">
                <b-col cols="6" class="font-weight-bold">
                  <p>{{ $t("event_details.label_telefon_tagsueber_mobile") }}</p>
                </b-col>
                <b-col cols="6">
                  <p>
                    {{ event.contactMobile }}
                  </p>
                </b-col>
              </b-row>
              <b-row v-if="event.publishPhone && event.contactPhone">
                <b-col cols="6" class="font-weight-bold">
                  <p>{{ $t("event_details.label_telefon_privat") }}</p>
                </b-col>
                <b-col cols="6">
                  <p>
                    {{ event.contactPhone }}
                  </p>
                </b-col>
              </b-row>
              <b-row v-if="event.publishEmail && event.email">
                <b-col cols="6" class="font-weight-bold">
                  <p>{{ $t("event_details.label_email") }}</p>
                </b-col>
                <b-col cols="6">
                  <p style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    <b-link :href="`mailto:${event.email}`" target="_blank" :title="event.email">
                      {{ event.email }}
                    </b-link>
                  </p>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col v-if="event.files.length > 0">
                  <b-dropdown
                    id="fileDownloadDropdown"
                    :text="$t('event_details.label_dateien_herunterladen')"
                    block
                  >
                    <b-dropdown-item
                      v-for="file in event.files"
                      :key="file.fileId"
                      @click="getFileData(file)"
                      >{{ file.fileName }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col>
                  <b-button @click="downloadInfoDocument" block>{{ $t("event_details.label_infoblatt_herunterladen") }}</b-button>
                </b-col>
              </b-row>
              <b-row v-if="!isOpen">
              </b-row>
              <b-row v-else-if="beforeStart">
                <b-col>

                  <b-modal id="modal-participationLimitation" hide-header-close no-close-on-backdrop no-close-on-esc content-class="shadow" :title="$t('event_liste.table_label_teilnahmeeinschränkung')">
                    <b-row>
                      <b-col>
                        <p class="text-danger" v-html="event.participationLimitation"></p>
                      </b-col>
                    </b-row>
                    <template #modal-footer="{ ok }">
                      <b-button size="sm" variant="primary" @click="ok()">
                        {{ $t('event_details.label_verstanden') }}
                      </b-button>
                    </template>
                  </b-modal>
                  <b-button v-if="beforeDeadline" :to="`/${$i18n.locale}/veranstaltungen/${event.id}/anmeldung`" variant="primary" block>
                    {{ $t("event_details.label_zur_anmeldung") }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col>
                  <b-button disabled block title="Anmeldeschluss abgelaufen" variant="primary">
                    {{ $t("event_details.label_zur_anmeldung") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row cols="12" class="mx-0 mt-4">
            <b-link :to="`/${$i18n.locale}/veranstaltungen/`">
              {{ $t("event_details.label_zurueck_zur_uebersicht") }}
            </b-link>
          </b-row>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col
          cols="12"
          class="d-block"
        >
          <div
            v-if="true"
            id="pageLoader"
            class="d-flex justify-content-center align-items-center py-5"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      beforeStart: false,
      beforeDeadline: false,
      isOpen: false,
      logo: '',
      event: null,
      events: []
    };
  },
  props: {
    eventId: {
      type: String,
      default: ''
    }
  },
  created() {
    this.getEvents();
  },
  methods: {
    mapEvents(data) {
      var tmp = [];
      data.forEach((event) => {
        let tmpEvent = event;

        tmpEvent.nwpType = this.$t(event.nwpType);
        tmpEvent.nwpCanton = this.$t(event.nwpCanton);
        tmpEvent.nwpCategoryKey = event.nwpCategory;
        tmpEvent.nwpCategory = this.$t(event.nwpCategory);
        tmpEvent.nwpEligibleKey = event.nwpEligible;
        tmpEvent.nwpEligible = tmpEvent.nwpEligible ? this.$t(event.nwpEligible + '_short') : '';
        tmpEvent.contactCanton = this.$t(event.contactCanton);
        tmpEvent.correspondenceLanguage = this.$t(event.correspondenceLanguage);
        tmpEvent.participationLimitation = event.participationLimitation ? event.participationLimitation.replace(/(?:\r\n|\r|\n)/g, '<br>') : '';
        tmpEvent.remark = event.remark ? event.remark.replace(/(?:\r\n|\r|\n)/g, '<br>') : '';

        tmp.push(tmpEvent);
      });
      return tmp;
    },
    getEvents() {
      this.$store
        .dispatch("wettkampf/getListe", {nwpType: "Lov.Sat.NWPType.MilleGruyere"})
        .then((resp) => {
          var events = resp.data;
          // Filtere geschlossene lokale Ausscheidungen heraus
          //events = events.filter(event => event.nwpEligible != "Lov.Sat.NWPEligible.MilleGruyereLocalContestClosedParticipantGroup" && event.nwpEligible != "Lov.Sat.NWPEligible.SprintLocalContestClosedParticipantGroup")
          // Wandle LIC Werte in menschlich leserliche Werte um
          this.events = this.mapEvents(events);
          // Sortiere Events nach Startdatum
          this.events.sort((a, b) => {
            var dateA = new Date(a.startDate);
            var dateB = new Date(b.startDate);
            return (dateA > dateB) ? 1 : ((dateB > dateA) ? -1 : 0)
          });

          for (let i = 0; i < this.events.length; i++) {
            if (this.events[i].id == this.eventId) {
              this.event = this.events[i];
              this.calculateDeadline();
              //todo better solution for magic string
              this.isOpen = this.events[i].nwpEligible != this.$t("Lov.Sat.NWPEligible.SprintLocalContestClosedParticipantGroup_short");
              break; // Verlassen den for-loop, wenn der passende Event gefunden werden konnte
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then(() => {
          this.getEventLogo();
          this.$helpers.updateParentUrl([{key: 'id', value: this.event.id}])
          this.displayParticipationLimitation();
        });
    },
    getEventLogo() {
      var file = this.event.logo;
      if (file) {
        this.$store
          .dispatch("dateien/getDocument", file)
          .then((resp) => {
            this.logo = `data:image/${this.event.logo.fileType};base64,${resp}`;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getFileData(file) {
      this.$store
        .dispatch("dateien/getDocument", file)
        .then((resp) => {
          this.downloadFile(resp, file.fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadInfoDocument() {
      var data = {
        eventId: this.event.id,
        languageCode: this.$i18n.locale.toString().toUpperCase()
      };
      this.$store
        .dispatch("dateien/getEventInfoDoc", data)
        .then((resp) => {
          this.downloadFile(resp, "Infoblatt.pdf");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    downloadFile(file, name) {
      const link = document.createElement("a");
      link.href = "data:application/octet-stream;base64," + file;
      link.setAttribute("download", name);
      link.click();
    },
    formatDate(dateStr) {
      var date = new Date(dateStr);
      const options = {day: "2-digit", month: "2-digit", year: "numeric"};
      return date.toLocaleDateString("de", options);
    },
    formatTime(ms) {
      let date = new Date()
      date.setTime(ms);
      const hours = date.getUTCHours().toString().padStart(2,0);
      const minutes = date.getUTCMinutes().toString().padStart(2,0);
      return `${hours}:${minutes} Uhr`;
    },
    calculateDeadline() {
      var deadline = new Date(this.event.deadline);
      var startDate = new Date(this.event.startDate);
      var currentDate = new Date();
      deadline.setHours(0,0,0,0);
      currentDate.setHours(0,0,0,0);
      this.beforeDeadline = deadline.getTime() >= currentDate.getTime();
      this.beforeStart =  startDate.getTime() >= currentDate.getTime();
    },
    displayParticipationLimitation() {
      if (this.event.participationLimitation) {
        this.$bvModal.show('modal-participationLimitation');
      }
    }
  },
};
</script>